import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "plume",
  backendBaseUrl: "https://ui-api.plumebot.laposte.fr/v0/",
  headerLogoUrl: "/assets/specific/plume/avatar.png",
  headerActions: ["menu"],
  reducedBotHasWelcomeText: true,
  reducedBotLogoUrl: "/assets/specific/plume/avatar.png",
  idrhSupport: { required: false },
  messageAvatarUrl: "/assets/specific/plume/avatar.png",
};
